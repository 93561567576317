import React from "react";
import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img
          src={
            "/logo.png"
          }
          className="App-logo"
          alt="logo"
        />
        <div>
          <p>
            <strong>PRICIS, s.r.o.</strong>
          </p>
          <p>Tř. Tomáše Bati 1716</p>
          <p>765 02 Otrokovice</p>
          <p>IČ: 25550225, DIČ: CZ25550225</p>
          <br />
          <p>
            <strong>Provozovna</strong>
          </p>
          <p>Areál Toma, a.s.</p>
          <p>Tř. Tomáše Bati</p>
          <p>budova č. 51</p>
          <p>Tel./Fax: 577 663 677</p>
          <p>Tel. výroba: 577 663 607</p>
          <p>E-mail: pricis@pricis.cz</p>
          <br />
          <p>
            <strong>Kontaktní osoby</strong>
          </p>
          <p>Stanislav Pricis</p>
          <p>jednatel</p>
          <p>mobil: 603 839 284</p>
          <hr />
          <p>
            <strong style={{ fontSize: "16px" }}>Výrobní možnosti</strong>
          </p>
          <br />
          <p>
            <strong>Vývoj, výroba, zpracování technické dokumentace</strong>
          </p>
          <p>Máme zkušenosti s výrobou:</p>
          <ul>
            <li>Potravinářství (nerez dopravníky, zakladače, dávkovače, drtiče, míchačky)</li>
            <li>Gumárenství (převíječky, formátovačky, trhačky, lisy, hnětače)</li>
            <li>Papírenství (lisy, rozvlákňovače, třídiče, mlýny)</li>
            <li>Recyklace (drtiče, trhačky, lisy, třídiče, separátory)</li>
            <li>Textilní (šičky, žehličky, lisy, řezačky)</li>
            <li>Zasněžování (konstrukce pro upevnění a manipulaci se zasněžovací technikou HKD)</li>
            <li>Chemický (laboratorní sudy, dopravníky, čerpadla)</li>
            <li>Horské technologie (zasněžování, vleky, lanovky, čerpací stanice, úprava vody)</li>
          </ul>
          <br />
          <p>
            <strong>Svařování:</strong>
          </p>
          <p>Vyrábíme ocelové konstrukce a svařence. Vytvoříme výrobní dokumentaci dle požadavků zákazníka. Svařujeme železné i neželezné kovy včetně hliníku.</p>
          <ul>
            <li>Svařování obalenou elektrodou</li>
            <li>Svařování metodou MIG/MAG (ocel, nerez)</li>
            <li>Svařování metodou TIG (ocel, nerez, hliník)</li>
            <li>Tváření plechů</li>
          </ul>
          <br />
          <p>
            <strong>Výroba konstrukcí pro umístění zasněžovací techniky HKD:</strong>
          </p>
          <p>Vyrábíme ocelové konstrukce a svařence (věže, sáně, upevňovací a kotvící prvky).</p>
          <p>Vytvoříme výrobní dokumentaci.</p>
          <ul>
            <li>Montáže a instalace zasněžovací techniky HKD</li>
            <li>Dodávky zasněžovací techniky HKD</li>
            <li>Rozvody technologických medií pro zasněžování</li>
          </ul>
          <br />
          <p>
            <strong>Obrábění</strong>
          </p>
          <p>Provádíme obrábění kovů klasickou metodou.</p>
          <p>Disponujeme obráběcími stroji vybavené digitálním odměřováním</p>
          <ul>
            <li>Horizontální vyvrtávačka osa X 1400mm, osa Y 1000mm, osa Z 1400 mm</li>
            <li>Frézka FA4</li>
            <li>Hrotový soustruh SN40 1500mm</li>
            <li>Radiální vrtačka VR4</li>
          </ul>
          <br />
          <p>
            <strong>
              Výroba jednoúčelových strojů a zařízení</strong>
          </p>
          <p>Dle požadavků navrhneme, zkonstruujeme stroj nebo zařízení, které následně vyrobíme</p>
          <ul>
            <li>Návrh řešení</li>
            <li>Modelování ve 3D</li>
            <li>Výkresová 2D dokumentace</li>
            <li>Výroba dílů</li>
            <li>Montáž</li>
          </ul>
          <br />
          <p>
            <strong>
              Generální opravy strojů a zařízení</strong>
          </p>
          <p>Opravíme jakýkoliv stroj nebo strojní zařízení.</p>
          <br />
          <p>
            <strong>
              Řízení strojů</strong>
          </p>
          <p>Vymyslíme a vytvoříme na míru software pro řízení vašeho stroje nebo zařízení</p>
          <br />
        </div>
      </header>
    </div>
  );
}

export default App;
